import React from "react";
import { Link } from "gatsby"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

import ReactTooltip from 'react-tooltip'

import styles from "assets/jss/nextjs-material-kit/pages/componentsSections/loginStyle.js";
// import beyond from ;

const UseStyles = makeStyles(styles);

// @material-ui/icons
// import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";

const communities = [
  {
    city: 'Dobrodzień',
    img: '',
    alt: '',
    title: 'evangelii gaudium',
    date: 'Czwartek	19:00',
    slug: 'wspolnota/evangelii-gaudium',
    more: ''
  },{
    city: 'Głubczyce ',
    img: '',
    alt: '',
    title: 'wieczernik',
    date: 'Czwartek 19:00',
    slug: 'wspolnota/wieczernik',
    more: ''
  },{
    city: 'Kędzierzyn - Koźle',
    img: '',
    alt: '',
    title: 'charis',
    date: 'Środa	19:00',
    slug: 'wspolnota/charis',
    more: ''
  },{
    city: 'Kędzierzyn - Koźle',
    img: '',
    alt: '',
    title: 'hallel',
    date: 'Środa	19:00',
    slug: 'wspolnota/hallel',
    more: ''
  },{
    city: 'Kluczbork ',
    img: '/comminitiesLogos/kluczbork.png',
    alt: '',
    title: 'effatha',
    date: 'Piątek	19:00',
    slug: 'wspolnota/effatha',
    more: ''
  },{
    city: 'Lewin Brzeski',
    img: '',
    alt: '',
    title: 'uwielbienia',
    date: 'Piątek 18:40',
    slug: 'wspolnota/uwielbienia',
    more: ''
  },{
    city: 'Nysa ',
    img: '',
    alt: '',
    title: 'miriam',
    date: 'Czwartek	19:00',
    slug: 'wspolnota/miriam',
    more: ''
  },{
    city: 'Nysa ',
    img: '',
    alt: '',
    title: 'niepokalana',
    date: 'Środa	19:15',
    slug: 'wspolnota/niepokalana',
    more: ''
  },{
    city: 'Olesno ',
    img: '/comminitiesLogos/olesno.jpg',
    alt: '',
    title: 'św. michała',
    date: 'Poniedziałek	19:00',
    slug: 'wspolnota/sw-michala',
    more: ''
  },{
    city: 'Opole ',
    img: '',
    alt: '',
    title: 'nowa jerozolima',
    date: 'Środa	19:30',
    slug: 'wspolnota/nowa-jerozolima',
    more: ''
  },{
    city: 'Otmuchów ',
    img: '/comminitiesLogos/otmochow.jpg',
    alt: '',
    title: 'maranatha',
    date: 'Środa	19:00',
    slug: 'wspolnota/maranatha',
    more: ''
  },{
    city: 'Ozimek',
    img: '',
    alt: '',
    title: 'emmanuel',
    date: 'Wtorek	19:00/20:00',
    slug: 'wspolnota/emmanuel',
    more: ''
  },{
    city: 'Prudnik',
    img: '',
    alt: '',
    title: 'cantate deo',
    date: 'Poniedziałek	19:00',
    slug: 'wspolnota/cantate-deo',
    more: ''
  },{
    city: 'Racibórz ',
    img: '',
    alt: '',
    title: 'magnificat',
    date: 'Wtorek	19:30',
    slug: 'wspolnota/magnificat',
    more: ''
  },{
    city: 'Strzelce Opolskie ',
    img: '',
    alt: '',
    title: 'emaus',
    date: 'Piątek	19:00',
    slug: 'wspolnota/emaus',
    more: ''
  },{
    city: 'Tułowice',
    img: '',
    alt: '',
    title: 'winnica',
    date: 'Poniedziałek	18:45',
    slug: 'wspolnota/winnica',
    more: ''
  },
]

export default function TeamSection() {
  const classes = UseStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          {communities.map((community, index) => 
            <GridItem xs={12} sm={6} md={3} key={index}>
              <Card style={{height: '100%'}}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h3>{community.city}</h3>
                </CardHeader>
                <CardBody 
                  data-tip="" 
                  data-for={community.title}
                  style={{    
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',}}>
                  
                  {community.date ? <h4 style={{textAlign: 'center'}}>{community.date} </h4> : ''}
                  <Button simple size="lg" style={{color: 'black', textAlign: 'center', fontWeight: 'bold'}}>{community.img ? <CropOriginalIcon /> : ''} {community.title}</Button>
                </CardBody>
                  {/* <Button round color="info"  disable={community.img === ''}> info </Button> */}
                  <ReactTooltip place="top" type="dark" effect="solid" id={community.title}>
                    {community.img ? <img
                      src={community.img}
                      alt="..."
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                      style={{maxWidth: '300px'}}
                      /> : ''}
                  </ReactTooltip>
                  <CardFooter>
                    <Button style={{width: '100%'}} round color="warning">
                      <Link to={community.slug}>więcej</Link>
                    </Button>
                  </CardFooter>
              </Card>
            </GridItem>
          )}
        </GridContainer>
      </div>
    </div>
  );
}
